import Block from '_core_ext/core/common/Block';
import plpServices from '_core_ext/components/plp/utils/plpServices';
import dropDownModule from '_core_ext/dropdown';
import eventMgr from '_core_ext/core/eventMgr';
import util from '_core_ext/util';
import addToCart from '../../pages/product/addToCart';
import layout from '_core_ext/layout';

const CHILDREN_COMPONENTS_IDS = [
    'sorting',
    'productView',
    'gridView',
    'productList',
    'refinementsContainer',
    'searchControls',
    'notifyMe'
];

export default class Plp extends Block {
    init() {
        super.init();
        this.settings = {};
        this.initConfig(this.settings);
        this.updateChildrenReferences();
        this.subscribeToChildrenEvents();
        this.productAddedToBagFromQuickShopMsg = this.config.productAddedToBagFromQuickShopMsg;
        plpServices.init();
        addToCart.init();
        dropDownModule.init({
            'options': {
                'globalEvents': 'search.update'
            }
        });

        if (layout.isMobile() || layout.isTablet()) {
            sessionStorage.updateScrollPos = 'false';
            if (sessionStorage.plpPageUrl === null) {
                sessionStorage.plpPageUrl = window.location.href.split('?')[0];
            } else if(sessionStorage.plpPageUrl !== window.location.href.split('?')[0]) {
                sessionStorage.scrollPos = 0;
                sessionStorage.plpPageUrl = window.location.href.split('?')[0];
            }
        }

        if (this.$el.find('.js-no-hits').length > 0) {
            $(document).trigger('search.nohit', '.js-no-hits');
        }

        if (this.config.loadOnInit) {
            var viewModeParams = {};
            var searchQueryUrl = location.href.split('#')[0];

            if (this.config.definedViewModes.indexOf('productViewMode') < 0) {
                // get product view mode from local storage or default value
                this.getById('productView', (component) => {
                    viewModeParams.productViewMode = component.getStoredViewMode() || component.currentViewMode;
                });
            }

            if (this.config.definedViewModes.indexOf('gridViewMode') < 0) {
                // get grid view mode from local storage or default value
                this.getById('gridView', (component) => {
                    viewModeParams.gridViewMode = component.getStoredViewMode() || component.currentViewMode;
                });
            }
            this.handleSearchQueryChanges({
                url: util.appendParamsToUrl(searchQueryUrl, viewModeParams),
                historyUrl: searchQueryUrl
            });
        }

        this.event('click', '.js-show-filters-trigger', this.triggerFiltersClick.bind(this));
        this.event('click', '.js-toggle-description-button', this.toggleDescription.bind(this));
        this.eventMgr('window.modechanged', this.onWindowModeChanged.bind(this));

        if (this.refinementsContainer && this.refinementsContainer !== 'undefined') {
            this.expandedRefinements = this.refinementsContainer.getExpandedRefinements();
        }

        this.checkOverflowCategoryDescription();

        //expand read more functionality
        this.expandReadMore();
    }

    expandReadMore() {
        var showReadMore = document.getElementsByClassName('show-read-more')[0];
        if (showReadMore) {
            var readMoreseo = Resources.VIEWMORE_SEO_PLP;
            var readLessseo = Resources.VIEWLESS_SEO_PLP;
            if (showReadMore.offsetHeight < showReadMore.scrollHeight) {
                $('<a href="javascript:void(0);" class="read-more"><span class="read-more-text">' + readMoreseo + '</span><span class="arrow arrow-down"></span></a>').insertAfter($(showReadMore));
                var readMore = $(".read-more");
                var readMoreText = readMore.find('.read-more-text');
                readMore.click(function () {
                    if (showReadMore.offsetHeight < showReadMore.scrollHeight) {
                        readMoreText.text(readLessseo);
                        $(this).addClass('read-less');
                        $(showReadMore).addClass('read-less');
                        $(this).find('.arrow').removeClass('arrow-down').addClass('arrow-up');
                    } else {
                        readMoreText.text(readMoreseo);
                        $(this).removeClass('read-less');
                        $(showReadMore).removeClass('read-less');
                        $(this).find('.arrow').removeClass('arrow-up').addClass('arrow-down');
                    }
                });
            } else {
                $(showReadMore).addClass('read-less');
            }
        }
    }

    toggleDescription() {
        this.$el.find('.js-toggle-description').toggleClass('is--opened');
    }

    isOverflowing($element) {
        return $element.get(0).scrollWidth > $element.get(0).offsetWidth;
    }

    addOverflowStatus($element) {
        var $toggleDescriptiom = this.$el.find('.js-toggle-description').not('.is--opened');
        if (this.isOverflowing($element)) {
            $toggleDescriptiom.addClass('o-collapsible');
        } else {
            $toggleDescriptiom.removeClass('o-collapsible');
        }
    }

    checkOverflowCategoryDescription() {
        var $categoryDescription = this.$el.find('.js-toggle-description');

        if (!$categoryDescription.length) {
            return;
        }

        var $categoryDescriptionText = $categoryDescription.find('.js-text-container');
        this.addOverflowStatus($categoryDescriptionText);
    }

    onWindowModeChanged() {
        this.checkOverflowCategoryDescription();
    }

    triggerFiltersClick() {
        this.refinementsContainer.$el.find('.js-show-filters').click();
    }

    getPlp(queryParams) {
        return eventMgr.execute('get.plp', queryParams);
    }

    getRefinementApplyUrl(queryParams) {
        return eventMgr.execute('get.refinement.apply.url', queryParams);
    }

    renderPlp(plpHtml) {
        var tmpDOM = $('<div>').append(plpHtml);
        var targetResultElement = tmpDOM.find('.js-products-container');
        this.$el.find('.js-products-container').html(targetResultElement.html()).removeClass('c-product-result__init-height');
        targetResultElement.remove();
        if (layout.isMobile() || layout.isTablet()) {
            setTimeout($(window).scrollTop(sessionStorage.scrollPos || 0), 300);
            sessionStorage.scrollPos = $(window).scrollTop();
            sessionStorage.updateScrollPos = 'true';
        }
        return eventMgr.execute('components.update', this.$el);
    }

    /**
    * @private
    * @function
    * @description replaces breadcrumbs, lefthand nav and product listing with ajax and puts a
    *              loading indicator over the product listing
    */
    handleSearchQueryChanges (queryParams) {
        return this.getPlp(queryParams)
            .then((plpHtml) => {
                return this.renderPlp(plpHtml);
            })
            .then(() => {
                history.pushState({}, document.title, (queryParams.historyUrl || queryParams.url));
                this.updateChildrenReferences();
                this.subscribeToChildrenEvents();
                let header = $('.header');
                if (header.length > 0) {
                    let headerHeight = header.outerHeight();
                    $('div.filters-sticky').css('transform', `translate3d(0, ${headerHeight}px, 0)`);
                    this.stickyFilters();
                }
                dropDownModule.init({
                    'options': {
                        'globalEvents': 'search.update'
                    }
                });
                $(document).trigger('init.all.carousels.insideselector', this.$el);
                if (this.refinementsContainer && this.refinementsContainer !== 'undefined') {
                    this.refinementsContainer.expandRefinements(this.expandedRefinements);
                }
                $(document).trigger('plp.productLoaded');
            });
    }

    applyRefinementsChanges(params) {
        this.getRefinementApplyUrl(params)
            .then((data) => {
                if (!data.url) {
                    return;
                }

                this.onHandleSearchQueryChanges({
                    url: data.url
                });
            });
    }

    /* eslint-disable */
    subscribeToChildrenEvents() {
        this.onChild('productView', 'productView.mode.changed', (queryParams) => {
            this.$el.trigger('viewMode.product.modeChanged', {
                productViewMode: this.productView.getViewMode(), 
                gridViewMode: this.gridView ? this.gridView.getViewMode() : undefined
            });
        });
        this.onChild('gridView', 'gridView.mode.changed', (queryParams) => {
            this.$el.trigger('viewMode.grid.modeChanged', {
                productViewMode: this.productView ? this.productView.getViewMode() : undefined, 
                gridViewMode: this.gridView.getViewMode()
            });
            this.onHandleSearchQueryChanges(queryParams);
        });
        this.onChild('refinementsContainer', 'refinementsContainer.refinement.selected', this.onHandleSearchQueryChanges.bind(this));
        this.onChild('refinementsContainer', 'refinementsContainer.refinement.applied', this.applyRefinementsChanges.bind(this));
        this.onChild('refinementsContainer', 'refinementsContainer.refinement.display.change', this.onRefinementDisplayChange.bind(this));
        this.onChild('sorting', 'sorting.sort.rule.selected', this.onHandleSearchQueryChanges.bind(this));
        this.onChild('productList', 'productList.grid.updated', this.onGridUpdated.bind(this));
        this.onChild('productList', 'productList.notifyme', this.onNotifyMe.bind(this));
        this.onChild('productList', 'productList.product.added.to.cart', this.onProductAddedToCart.bind(this));
        this.onChild('searchControls', 'searchControls.pagination.link.clicked', this.onHandleSearchQueryChanges.bind(this));
        this.onChild('notifyMe', 'notifyMe.form.submit', this.onNotyFeFormSubmit.bind(this));
        this.onChild('notifyMe', 'notifyMe.dialog.close', this.onNotifyMeDialogClose.bind(this));
    }
    /* eslint-enable */

    updateChildrenReferences() {
        CHILDREN_COMPONENTS_IDS.forEach((childComponentId) => {
            this.getById(childComponentId, (childComponent) => {
                this[childComponentId] = childComponent;
            });
        });
    }

    onGridUpdated(params) {
        var {paginationParams} = params;
        [
            'sorting',
            'productView',
            'gridView',
        ].forEach((cmpId) => {
            if (this[cmpId]) {
                this[cmpId].updateQueryUrls(paginationParams);
            }
        });
    }

    onNotifyMe(params) {
        this.notifyMe.show(params);
    }

    onNotyFeFormSubmit() {
        this.productList.notifyMeFormSubmited();
    }

    onNotifyMeDialogClose(wasFormSubmitted) {
        if (wasFormSubmitted) {
            return;
        }
        this.productList.onNotifyMeDialogClose();
    }

    onRefinementDisplayChange(params) {
        if (params.isExpanded) {
            this.expandedRefinements.push(params.refinementId);
        } else {
            this.expandedRefinements = this.expandedRefinements.filter((id) => id !== params.refinementId);
        }
    }

    onHandleSearchQueryChanges(queryParams) {
        util.scrollTo(0, 0);
        this.handleSearchQueryChanges(queryParams);
    }

    onProductAddedToCart() {
        var $productAddedToCartMsg = this.$el.find('.js-product-added-to-cart-msg');

        $productAddedToCartMsg.text(this.productAddedToBagFromQuickShopMsg);
        $productAddedToCartMsg.removeClass('is--hidden');
        setTimeout(() => {
            $productAddedToCartMsg.addClass('is--hidden');
            $productAddedToCartMsg.text('');
        }, 2000);
    }

    stickyFilters() {
        var observer = new IntersectionObserver(function(entries) {
            if (entries[0].intersectionRatio === 0)
                document.querySelector(".filters-sticky").classList.add("sticky-state");
            else if (entries[0].intersectionRatio === 1)
                document.querySelector(".filters-sticky").classList.remove("sticky-state");
        }, {
            threshold: [0, 1]
        });
        observer.observe(document.querySelector(".js-filters-sticky-top"));
    }
}
